const welcomeMessage = `
Welcome to (class=yellow)(Nick Jasinski's) Website

  * (class=green)(Github):         [https://github.com/njasi](https://github.com/njasi)
  * (class=green)(Linkedin):       [https://www.linkedin.com/in/njasi](https://www.linkedin.com/in/njasi)
  * (class=green)(Email):          [nick@jasinski3.com](mailto:nick@jasinski3.com)

(class=cyan bold)(About Me):
Hi! I'm Nick, a software developer with a strong background in machine learning, fullstack web development, data processing, and much more.

I am a determined and hardworking person who genuinely enjoys solving problems and making anything with code. I view every challenge as a puzzle that needs a solution.

(class=cyan bold)(Education):
* (class=green)(California Institute of Technology)      2018-23
  Bachelor of Science in Computer Science
* (class=green)(Fullstack Academy, Chicago)              September 2019 - December 2020

(class=cyan bold)(Experience):
* (class=green)(Caltech IMSS Rep)                        April 2022 - June 2023
  Provided technical support to peers at Caltech and managed public house devices.

* (class=green)(ML Teaching Assistant)                   September 2021 - March 2022
  Taught CS 12: TensorFlow, a fast-paced class in machine learning for both undergraduate and graduate students.

* (class=green)(Frontend Software Engineer Intern)       July 2020 - September 2020
  Worked with a designer through Figma and developed frontend functionality for the [Zette chrome extension](https://www.zette.com/).

(class=cyan bold)(What I Bring to the Table):
  * Expertise in Python, TensorFlow2, C, C++, C#, Javascript, Java, Ocaml, Haskell, R, MatLab, Node.js, React+Redux
  * Strong problem-solving skills and a knack for turning ideas into reality.
  * Experience in working on difficult projects in teams

Reach out to me at [nick@jasinski3.com](mailto:nick@jasinski3.com)!

Just for fun, this website is a simple bash emulator.
  Coming soon: redirection and piping (might fully remake with proper AST parsing)
For a list of available commands please type "(command=help)(help)"

The source for this project is available at [github.com/njasi/bash_website](https://github.com/njasi/bash_website)
    `;

export default welcomeMessage;
